.icon-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;

  > span {
    cursor: pointer;
  }

  .ui.dropdown > .left.menu {
    right: 0;
    border-radius: 0;
    transform: translate(0, 17px);
    box-shadow: 0 !important;
  }
}

.mobile {
  margin-right: 0;
}

.gear-dropdown {
  > div {
    .glyph-icon {
      &::before {
        font-size: 1em;
      }
    }
  }

  > i.icon {
    width: 8px !important;
  }
}

.dropdown {
  .menu {
    background-color: #edf0f5 !important;
  }
}

.sites-dropdown-wrapper {
  margin: auto;
  background-color: #0680df;

  .sites-dropdown {
    padding: 8px 30px;

    @media only screen and (max-width: 450px) {
      padding: 8px 6px;
    }
  }
}

.tags-bar {
  margin-top: -1px;
  position: relative;
  background-color: #edf0f5;
  box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
  height: 51px;
  width: 100%;

  .menu-item {
    padding: 0 40px;
    user-select: none;
    cursor: pointer;
    border: none;
  }

  .menu-item-wrapper {
    border: none !important;
    padding: 10px;
  }

  .ui.label {
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: hsla(0, 0%, 100%, 0.35);
    font-size: 0.925em;
    cursor: pointer;
    border-width: 1px;
    color: gray;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0);
      color: black;
    }
  }

  .menu-item-wrapper.active,
  .menu-item-wrapper {
    outline: none !important;
  }

  .menu-item.active {
    border: none !important;
  }

  .scroll-menu-arrow {
    padding: 12px;
    cursor: pointer;
    color: grey;
    transform: scale(0.5) !important;
  }

  .scroll-menu-arrow--disabled {
    visibility: hidden;
  }

  .label.circular {
    cursor: pointer;
    border-width: 3px;
    font-size: 0.925em;
    outline: none !important;

    .tag-span {
      outline: none !important;
    }

    .tag-span.active {
      text-decoration: underline;
      color: black;
    }
  }
}

.layout-main {
  padding: 0;
  transition: transform 0.3s ease-out;
  transform: scaleY(1);

  .app-route-wrapper {
    padding: 10px !important;

    @media only screen and (max-width: 450px) {
      padding: 10px !important;
    }
  }
}

.app-route-wrapper.with-tagsbar-fixed {
  margin-top: 61px !important;
}

.app-route-wrapper.with-filterbar-and-tagsbar-fixed {
  padding-top: 110px !important;
}

.app-route-wrapper.with-filterbar-fixed {
  padding-top: 110px !important;
}

.tags-bar.fixed {
  position: fixed;
  z-index: 2;
  height: 51px !important;
  width: 100%;
}
