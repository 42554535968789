.infocard {
	padding: 5px 5px 5px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	cursor: pointer;
	box-shadow: 0 0px 23px -5px rgb(0 0 0 / 35%), 0 0px 0px -8px rgb(0 0 0 / 50%);
}

.infocard-desktop {
	padding: 25px 5px 25px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	cursor:pointer;
	box-shadow: 0 0px 23px -5px rgb(0 0 0 / 35%), 0 0px 0px -8px rgb(0 0 0 / 50%);
}