.icon-library-modal-dark {
	display: flex;
	align-items: flex-start;
	max-width: 50%;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90%;
	@media only screen and (max-width: 600px) {
		max-width: 100%;
	}
	scrollbar-width: thin !important;
	scrollbar-width: 1px;
	background-color: #3c3c3c;
	border-radius: 0px;
	&::-webkit-scrollbar {
		width: 4px; /* Safari and Chrome */
		background-color: gray;
	}
}

.icon-library-modal-light {
	display: flex;
	align-items: flex-start;
	max-width: 50%;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90%;
	@media only screen and (max-width: 600px) {
		max-width: 100%;
	}
	scrollbar-width: thin !important;
	background-color: #edf0f5;
	&::-webkit-scrollbar {
		width: 4px; /* Safari and Chrome */
		background-color: lightgray;
	}
}
