.splash-screen {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #edf0f5;
	// @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);

	.splash-container {
		max-width: 40px;
		max-height: 40px;
		margin: 0px auto;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -20px;
		margin-top: -20px;
	}

	.loadingGif {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		// -webkit-animation: pageStart 2s infinite ease-in-out;
		// animation: pageStart 2s infinite ease-in-out;
	}

	@-webkit-keyframes pageStart {
		0%,
		100% {
			-webkit-transform: scale(0.3);
		}
		50% {
			-webkit-transform: scale(1);
		}
	}

	@keyframes pageStart {
		0%,
		100% {
			transform: scale(0.3);
			-webkit-transform: scale(0.3);
		}
		50% {
			transform: scale(1);
			-webkit-transform: scale(1);
		}
	}
}
