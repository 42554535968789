* {
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	font-family: "Open Sans", "Helvetica Neue", sans-serif;
	font-size: $fontSize;
	color: $textColor;
	background-color: $bodyBgColor;
	margin: 0;
	padding: 0;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
}

a {
	text-decoration: none;
}

.layout-wrapper {
	padding: 0;
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
}

.ui.menu .ui.dropdown .menu > div.item {
	background-color: white !important;
}
.ui.menu .ui.dropdown .menu > div.item:hover {
	//   background: rgba(0, 0, 0, 0.5) !important;
	//   color: rgba(0, 0, 0, 0.95) !important;
	background: #cecece;
}

.ui.menu .ui.dropdown .menu > div.dark-item {
	background-color: #3c3c3c !important;
	color: lightgray !important;
}
.ui.menu .ui.dropdown .menu > div.dark-item:hover {
	//   background: rgba(0, 0, 0, 0.5) !important;
	color: lightgray !important;
	background: #3c3c3c;
}

.modal-left-arrow {
	position: absolute;
	top: 0px;
	height: 100%;
	border-style: solid;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	left: -190px;
	z-index: 444444;

	span {
		color: white !important;
	}
}

.modal-right-arrow {
	position: absolute;
	top: 0px;
	height: 100%;
	border-style: solid;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	right: -200px;
}

.cover-parent {
	.art-by {
		position: absolute;
		right: 0px;
		bottom: 0px;
		z-index: 100000;
		padding: 10px;
		min-width: 150px;
		min-height: 25px;
		max-height: 25px;
		opacity: 0;
		transition: 0.3s all ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		flex: 1;
		cursor: pointer;

		.title {
			display: flex;
			font-weight: lighter;
			font-size: 12px;
			flex: 0.35;
		}

		.author {
			display: flex;
			font-size: 12px;
			font-weight: bold;
			padding-left: 5px;
			flex: 0.65;
			white-space: nowrap;
		}
	}

	&:hover {
		.art-by {
			opacity: 1;
		}
	}
}

.fit-modal {
	width: 100% !important;
	position: relative !important;
	margin-top:1px !important;
}
