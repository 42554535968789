	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/01/2021 16:38
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-settings:before { content: "\f100"; }
.flaticon-settings-1:before { content: "\f101"; }
.flaticon-wall-clock:before { content: "\f102"; }
.flaticon-arrow-down-sign-to-navigate:before { content: "\f103"; }
.flaticon-shuffle:before { content: "\f104"; }
.flaticon-email:before { content: "\f105"; }
.flaticon-email-1:before { content: "\f106"; }
.flaticon-facebook:before { content: "\f107"; }
.flaticon-twitter:before { content: "\f108"; }
.flaticon-reddit:before { content: "\f109"; }
.flaticon-upload:before { content: "\f10a"; }
.flaticon-sun:before { content: "\f10b"; }
.flaticon-moon:before { content: "\f10c"; }
.flaticon-list-text:before { content: "\f10d"; }
.flaticon-grid:before { content: "\f10e"; }
.flaticon-tag:before { content: "\f10f"; }
.flaticon-tag-1:before { content: "\f110"; }
.flaticon-info:before { content: "\f111"; }
.flaticon-info-1:before { content: "\f112"; }
.flaticon-file:before { content: "\f113"; }
.flaticon-file-1:before { content: "\f114"; }
.flaticon-shield:before { content: "\f115"; }
.flaticon-shield-1:before { content: "\f116"; }
.flaticon-plus:before { content: "\f117"; }
.flaticon-plus-1:before { content: "\f118"; }
.flaticon-star:before { content: "\f119"; }
.flaticon-star-1:before { content: "\f11a"; }
.flaticon-share:before { content: "\f11b"; }
.flaticon-share-1:before { content: "\f11c"; }
.flaticon-paper-plane:before { content: "\f11d"; }
.flaticon-paper-plane-1:before { content: "\f11e"; }
.flaticon-list:before { content: "\f11f"; }
.flaticon-list-1:before { content: "\f120"; }
.flaticon-more:before { content: "\f121"; }
.flaticon-more-1:before { content: "\f122"; }
.flaticon-picture:before { content: "\f123"; }
.flaticon-picture-1:before { content: "\f124"; }
.flaticon-comment:before { content: "\f125"; }
.flaticon-comment-1:before { content: "\f126"; }
.flaticon-loupe:before { content: "\f127"; }
.flaticon-up:before { content: "\f128"; }
.flaticon-square:before { content: "\f129"; }
.flaticon-up-1:before { content: "\f12a"; }
.flaticon-square-1:before { content: "\f12b"; }
.flaticon-up-2:before { content: "\f12c"; }
.flaticon-up-3:before { content: "\f12d"; }
.flaticon-right-arrow:before { content: "\f12e"; }
.flaticon-right-arrow-1:before { content: "\f12f"; }
.flaticon-left-arrow:before { content: "\f130"; }
.flaticon-left-arrow-1:before { content: "\f131"; }
.flaticon-bell:before { content: "\f132"; }
.flaticon-bell-1:before { content: "\f133"; }
.flaticon-messenger:before { content: "\f134"; }
.flaticon-link:before { content: "\f135"; }
.flaticon-linkedin:before { content: "\f136"; }
.flaticon-reddit-1:before { content: "\f137"; }
.flaticon-bank:before { content: "\f138"; }
.flaticon-bank-1:before { content: "\f139"; }
.flaticon-reload:before { content: "\f13a"; }
.flaticon-reload-1:before { content: "\f13b"; }
.flaticon-download:before { content: "\f13c"; }