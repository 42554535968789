.selector {
	z-index: 10000;
	color: rgba(0, 0, 0, 0.6);
	z-index: 1;
	width: 150px;
	padding: 0px;
	margin: 0px;

	#select-field {
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		background-color: white !important;
		width: 150px;
		height: 41px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 10px;
		color: rgba(0, 0, 0, 1);
		cursor: pointer;
		&:hover {
			color: rgba(0, 0, 0, 0.8);
		}
		p {
			margin: 0px;
			padding: 0px;
			flex: 0.7;
		}
		div {
			flex: 0.3;
		}
	}

	#option-list {
		z-index: 10000;
		position: absolute;
		width: 100%;
		margin: 0px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
		background: rgba(237, 240, 245, 1);
		color: rgba(0, 0, 0, 0.6);
		border-radius: 6px;
		list-style: none;
		box-sizing: border-box;
		padding: 0px;
		width: 150px;
		transition: opacity 0.4s ease, transform 0.2s cubic-bezier(0.2, 0.9, 0.4, 1.1);
		-webkit-transform: 0.2s cubicbezier(0.2, 0.9, 0.4, 1.1);
		// opacity 0.8s ease, transform 0.4s cubic-bezier(0.2, 0.9, 0.4, 1.1);

		.option {
			width: 150px;
			height: 43px;
			margin: 0px;
			display: flex;
			align-items: center;
			padding-left: 10px;
			border-radius: 6px;
			margin: 0px;
			cursor: pointer;

			p {
				margin: 0px;
				padding: 0px;
			}

			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}

	@keyframes tilt-up {
		40%,
		60% {
			transform: perspective(500px) rotateX(8deg);
		}
	}

	@keyframes tilt-down {
		40%,
		60% {
			transform: perspective(500px) rotateX(-8deg);
		}
	}
}

.selector-dark {
	z-index: 10000;
	color: rgba(255, 255, 255, 0.6);
	z-index: 1;
	width: 150px;
	padding: 0px;
	margin: 0px;

	#select-field {
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		background-color: #303030 !important;
		width: 150px;
		height: 41px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 10px;
		color: rgba(255, 255, 255, 0.6);
		cursor: pointer;
		&:hover {
			color: rgba(255, 255, 255, 0.8);
		}
		p {
			margin: 0px;
			padding: 0px;
			flex: 0.7;
		}
		div {
			flex: 0.3;
		}
	}

	#option-list {
		z-index: 10000;
		position: absolute;
		width: 100%;
		margin: 0px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
		background: rgba(60, 60, 60, 1);
		color: rgba(255, 255, 255, 0.6);
		border-radius: 6px;
		list-style: none;
		box-sizing: border-box;
		padding: 0px;
		width: 150px;
		transition: opacity 0.4s ease, transform 0.2s cubic-bezier(0.2, 0.9, 0.4, 1.1);
		-webkit-transform: 0.2s cubicbezier(0.2, 0.9, 0.4, 1.1);
		// opacity 0.8s ease, transform 0.4s cubic-bezier(0.2, 0.9, 0.4, 1.1);

		.option {
			width: 150px;
			height: 43px;
			margin: 0px;
			display: flex;
			align-items: center;
			padding-left: 10px;
			border-radius: 6px;
			margin: 0px;
			cursor: pointer;

			p {
				margin: 0px;
				padding: 0px;
			}

			&:hover {
				color: rgba(255, 255, 255, 0.8);
			}
		}
	}

	@keyframes tilt-up {
		40%,
		60% {
			transform: perspective(500px) rotateX(8deg);
		}
	}

	@keyframes tilt-down {
		40%,
		60% {
			transform: perspective(500px) rotateX(-8deg);
		}
	}
}
