.post-card {
	display: flex;
	flex-direction: column;
	padding: 20px;
	padding-bottom: 0;

	@media only screen and (min-width: 800px) {
		margin: 8px !important;
	}
}

.post-title {
	cursor: pointer;
	color: black !important;
}

.inpost-link {
	color: currentColor;
	font-weight: bold;
	background: linear-gradient(currentColor, currentColor) bottom / 0 0.1em no-repeat;
	transition: 0.1s background-size;
	text-decoration: none;
	font-size: 16px;
	padding: 0px;

	&:hover {
		background-size: 100% 0.1em;
	}
	&:visited {
		color: #9e9e9e !important;
	}
}

.favourite-link {
	color: currentColor;
	font-weight: bold;
	background: linear-gradient(currentColor, currentColor) bottom / 0 0.1em no-repeat;
	transition: 0.1s background-size;
	text-decoration: none;

	&:hover {
		background-size: 100% 0.1em;
	}
}

.inpost-link-light {
	color: currentColor;
	font-weight: bold;
	background: linear-gradient(currentColor, currentColor) bottom / 0 0em no-repeat;
	transition: 0.1s background-size;
	text-decoration: none;
	font-size: 16px;
	padding: 0px;
	// margin-left: 15px;

	&:hover {
		padding: 0px;
		background-size: 100% 0.1em;
	}

	&:visited {
		color: #9e9e9e !important;
	}
}

.favourite-link-light {
	color: currentColor;
	font-weight: bold;
	background: linear-gradient(currentColor, currentColor) bottom / 0 0.1em no-repeat;
	transition: 0.1s background-size;
	text-decoration: none;

	&:hover {
		background-size: 100% 0.1em;
	}
}

.post-card-body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	background-color: transparent;
	z-index: 99998;
	.post-card-image-listview-desktop {
		height: 100%;
		background-color: transparent;
		z-index: 99998;
		cursor: pointer;
		img {
			height: 175px;
			background-color: transparent;
			object-fit: contain;
			margin-left: 20px;
			padding-bottom: 7px;
			transition: all 0.2s; /* Animation */
			z-index: 99998;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
			&:hover {
				transform: scale(1.65) !important;
				margin-right: 75px;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
				background-color: transparent;
				z-index: 99999;
			}
		}
	}
}

.post-card-footer {
	padding-top: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;

	width: calc(100%);
	margin-left: -10px;

	.post-date {
		font-size: 0.9em;
	}

	// @media only screen and (min-width: 992px) {
	//   display: flex;
	//   flex-direction: row;

	//   .post-date {
	//     position: relative;
	//   }
	// }
}

.post-card-image-listview-mobile {
	img {
		width: 100%;
		padding-bottom: 20px;
	}
}

.post-engagement-favourite {
	text-align: right;
	padding-right: 5px;
	padding-bottom: 5px;
	padding-top: 12px;
	margin-left: 0px;

	i {
		color: #9e9e9e;
		cursor: pointer;
		&::before {
		}
	}
}

.ui.popup.share-popup {
	border: 0 !important;
	padding: 0 !important;
}

.highlight-wrapper {
	padding: 4px 10px;
	display: flex;
}

.post-engagement-upvote {
	.highlight-wrapper {
		padding: 4px 6px 4px 5px;
	}
}

.similar-single-post {
	border-top: 1px solid rgba(204, 204, 204, 0.75);
}
.dark-body .similar-single-post {
	border-top: 1px solid rgba(204, 204, 204, 0.25);
}
