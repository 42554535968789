@import url("https://fonts.googleapis.com/css?family=Open+Sans:600, 300");

.disclaimer-container {
  background-color: #555;
  color: #fff;
  display: block;
  font-family: "Open Sans", sans-serif;
  position: fixed;
  left: 20px;
  bottom: 15px;
  z-index: 1000;
  width: 47%;
  height: auto;
  border-radius: 7px;
  padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

  &--full {
    width: 93%;
    right: 15px;
  }

  .disclaimer-text {
    // padding: 8px;
    // -------------
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 7px;
    // width: 100%;
    justify-content: center;
    text-align: left;
    height: 100%;
    gap: 15px;
    flex: 0.75;

    svg {
      font-size: 55px;
      color: #8a8a8a;
    }

    span {
      width: 90%;
      color: #8a8a8a;
      text-align: left;
      font-weight: bold;
      font-size: 13px;
    }

    &__cookie-top {
      font-size: 22px;
      display: flex;
      align-items: center;
      gap: 7px;

      img {
        width: 22px;
        height: 22px;
      }
    }

    &__cookie-bottom {
      span {
        font-size: 14px;
        font-weight: 400;
      }

      label {
        cursor: pointer;
        color: #61d0d1;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .disclaimer-text p {
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .disclaimer-text p span:before {
    color: #fff;
  }

  .disclaimer-text p a {
    color: #fff;
  }

  .accept-button {
    // margin-left: 16px !important;
    margin: auto;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    span {
      &:hover {
        color: white !important;
      }
    }
  }

  a {
    color: #8dcdff !important;
  }
}

.c-dark {
  background-color: #555;

  .disclaimer-text__cookie-top {
    color: white;
  }

  .disclaimer-text__cookie-bottom {
    span {
      color: white;
    }
  }
}

.c-light {
  background-color: #edf0f5;

  .disclaimer-text__cookie-top {
    color: #000;
  }

  .disclaimer-text__cookie-bottom {
    span {
      color: #000;
      opacity: 0.7;
    }
  }
}

.disclaimer-container.show {
  display: block;
}

.pp {
  bottom: 15px;
  position: absolute;
  a {
    text-decoration: none;
    border-bottom-style: 3px;
    border-bottom-color: #8a8a8a;
    b {
      font-size: 10px;
      width: 100%;
      color: #8a8a8a;
      &:hover {
        color: white;
      }
    }
  }

  b {
    color: #8a8a8a;
  }
}

.cookie-cont-wrapper {
  display: flex !important;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.disclaimer-actions {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  gap: 5px;
  padding-top: 10px;

  .button-1 {
    padding: 5px;
    border: none;
    background-color: #61d0d1;
    color: white;
    height: 40px;
    border: 4px;
    cursor: pointer;
  }

  .button-2 {
    padding: 5px;
    border: none;
    color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    height: 40px;
    border: 4px;
    cursor: pointer;
  }

  .button-2-dark {
    padding: 5px;
    border: none;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    border: 4px;
    cursor: pointer;
  }
}

.cookie-modal-dark {
  display: flex;
  align-items: flex-start;
  max-width: 550px;
  overflow-y: scroll;
  max-height: 60%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #3c3c3c !important;
  scrollbar-color: #fff;
  padding: 0px;
  z-index: 20;

  .cookie-body {
    max-height: 350px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    background-color: #3c3c3c;
    box-shadow: none;
    color: #fff;

    /* Default scrollbar styles */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track style */
    &::-webkit-scrollbar-track {
      background-color: #3c3c3c;
    }

    /* Thumb style */
    &::-webkit-scrollbar-thumb {
      background-color: #666;
    }

    /* Button style (up/down) */
    &::-webkit-scrollbar-button {
      background-color: #ccc;
      width: 3px;
    }

    &__title {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;

      img {
        width: 17px;
        height: 17px;
      }
    }

    &__content {
      font-size: 13px;
      font-weight: 300;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 10px;
    }
  }

  .cookie-footer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0px;
    position: absolute;
    width: 99%;
    z-index: 100;
    background-color: #3c3c3c;

    &__left {
      display: flex;
      gap: 7px;

      .accept-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        border: none;
        background-color: #61d0d1;
        color: #fff;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: 600;
      }

      .reject-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        font-weight: 600;
        color: #fff;
      }
    }

    &__right {
      .save-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}

.cookie-modal-light {
  display: flex;
  align-items: flex-start;
  max-width: 550px;
  overflow-y: scroll;
  max-height: 60%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #fff;
  scrollbar-color: #3c3c3c;
  padding: 0px;
  z-index: 20;

  .cookie-body {
    max-height: 350px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    background-color: #fff;
    box-shadow: none;

    /* Default scrollbar styles */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track style */
    &::-webkit-scrollbar-track {
      background-color: #cccccc;
    }

    /* Thumb style */
    &::-webkit-scrollbar-thumb {
      background-color: #666;
    }

    /* Button style (up/down) */
    &::-webkit-scrollbar-button {
      background-color: #ccc;
      width: 3px;
    }

    &__title {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;

      img {
        width: 17px;
        height: 17px;
      }
    }

    &__content {
      font-size: 13px;
      font-weight: 300;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 10px;
    }
  }

  .cookie-footer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0px;
    position: absolute;
    width: 99%;
    z-index: 100;
    background-color: #fff;

    &__left {
      display: flex;
      gap: 7px;

      .accept-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        border: none;
        background-color: #61d0d1;
        color: #fff;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: 600;
      }

      .reject-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        font-weight: 600;
      }
    }

    &__right {
      .save-btn {
        font-family: "Lato", "Helvetica Neue", "Arial", "Helvetica",
          "sans-serif";
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        font-weight: 600;
      }
    }
  }
}

.cookie-modal-head-dark {
  width: 100%;
  border-radius: 0px;
  background-color: #3c3c3c !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: white;
      font-size: 14px;
    }
  }
}

.cookie-modal-head-light {
  border-radius: 0px;
  width: 100%;
  background-color: #fff !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: black;
      font-size: 14px;
    }
  }
}

.cookie-close-action {
  svg {
    padding: 5px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.cookie-text-content {
  width: 100%;
  height: 50px;
  display: block !important;
  padding: 10px 0px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14;
}

.cookie-item {
  width: 100%;
  height: 45px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 4px;
  justify-content: space-between;

  .cookie-item-head {
    font-weight: 900;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }
}

#cookie-content-section {
  display: flex !important;
  flex-direction: column !important;
  align-content: space-between !important;
  padding: 0px !important;
}

.cookie-privacy {
  cursor: pointer !important;
  color: #61d0d1 !important;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    color: #61d0d1 !important;
    text-decoration: underline;
  }
}
