.layout-topbar {
  position: fixed;
  height: 50px;
  padding: 0.7em 1.5em 0em 1.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: grey;
  z-index: 999;
  @include clearfix();
  background-color: $headerColor;
  @include transition(left $transitionDuration);

  .layout-topbar-icons {
    float: right;
    display: block;
    -moz-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;

    button {
      position: relative;
      color: $topbarItemColor;
      margin-left: 20px;
      display: inline-block;
      text-decoration: none;
      @include transition(color $transitionDuration);
      overflow: visible;

      &:hover {
        color: $topbarItemHoverColor;
      }

      span {
        &.layout-topbar-icon {
          font-size: 2em;
        }

        &.layout-topbar-item-text {
          font-size: 20px;
          display: none;
        }

        &.layout-topbar-badge {
          position: absolute;
          font-size: 10px;
          right: -5px;
          top: -5px;
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          color: $topbarItemBadgeColor;
          background-color: $topbarItemBadgeBgColor;
          @include border-radius(50%);
        }
      }
    }

    .layout-topbar-search {
      padding: 0;
      position: relative;
      display: inline-block;
      top: -4px;

      input {
        display: inline-block;
        border: 0 none;
        font-size: $fontSize;
        background: transparent;
        border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
        outline: 0 none;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $topbarSearchInputColor;
        width: 100px;
        padding: 1px 20px 1px 1px;
        margin: 0px;
        @include border-radius(2px);

        &::-webkit-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &:-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &::-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &:-ms-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
      }

      .layout-topbar-search-icon {
        font-size: 18px;
        position: absolute;
        top: -1px;
        right: 0px;
      }

      &:hover {
        input {
          border-bottom-color: $topbarItemHoverColor;
          &::-webkit-input-placeholder {
            opacity: 1;
          }
          &:-moz-placeholder {
            opacity: 1;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
          &:-ms-input-placeholder {
            opacity: 1;
          }
        }

        .layout-topbar-search-icon {
          color: $topbarItemHoverColor;
        }
      }
    }
  }

  .layout-menu-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: $topbarItemColor;
    @include transition(color $transitionDuration);

    span {
      font-size: 2em;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }

  button {
    cursor: pointer;
  }
}

.gear-icon-container {
  position: relative;

  &:hover {
    .tooltip-container {
      display: block;
    }
  }

  .tooltip-arrow {
    position: absolute;
    bottom: -16.5px;
    width: 9px;
    height: 10px;
    background-color: black;

    &.left {
      transform: skewY(-42deg);
      right: 67%;
    }
    &.right {
      transform: skewY(42deg);
      right: 18px;
    }
  }

  .tooltip {
    position: absolute;
    bottom: -41.5px;
    right: 13px;
    padding: 7.5px 11px;
    background-color: #1b1c1e;
    color: white;
    border-radius: 4px;
    font-size: 11.5px;
  }
}

.tooltip-container {
  display: none;
}

.loginRegister {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  div {
    width: 50%;
    text-align: center;
    padding: 1rem 0;
    position: relative;
    color: black;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:first-child::after {
      position: absolute;
      content: "";
      height: 21px;
      width: 2px;
      left: 98%;
      top: 13px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.modal-container {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 20000000;

  .backdrop {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 50000000;
  }

  .card-3d-wrapper {
    z-index: 500000001;
    width: 450px;
    height: 540px;
    background-color: transparent;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    perspective: 800px;
  }

  .card-3d-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.5);
  }

  .otp-input-light {
    padding: 0px 9px 0px 9px;
    font-size: 15px;
    height: 48px;
    background-color: #edf0f5 !important;
    border: 2px solid #edf0f5;
    box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
    border-radius: 4px;
    width: 75%;
    display: flex;
    line-height: 22px;
    letter-spacing: 0.5px;
    outline: none;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;

    &:focus,
    &:active {
      border: none;
      outline: none;
      box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
    }
  }

  .otp-container {
    padding-bottom: 1rem;

    .textfield {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: 1.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      .form-group {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
      }

      .input-icon {
        position: absolute;
        top: 0;
        left: 18px;
        height: 48px;
        font-size: 20px;
        line-height: 48px;
        text-align: left;
        color: #a5a6a8;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
      }

      input {
        padding: 0px 9px 0px 9px;
        font-size: 15px;
        height: 48px;
        background-color: #edf0f5 !important;
        border: 2px solid #edf0f5;
        box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
        border-radius: 4px;
        width: 100%;
        display: flex;
        flex: 0.2;
        line-height: 22px;
        letter-spacing: 0.5px;
        outline: none;
        color: gray;
      }
      input:focus,
      input:active {
        border: none;
        outline: none;
        box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
        color: red !important;
      }

      .passwordField-container {
        position: relative;

        .eye-icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          top: 0;
          height: 100%;
          width: 40px;

          i {
            cursor: pointer;
            position: absolute;
            // right: 8px;
            // top: 6px;
          }
        }
      }
    }
  }

  .card-front,
  .card-back {
    // background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 300%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal-header {
      width: 70%;
      display: flex;
      font-family: Poppins;
      color: gray;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .modal-body {
      padding: 1rem 3rem;

      .title {
        font-size: 13px;
        color: gray;
        font-weight: 600;
        margin-bottom: 4px;
        &:hover {
          color: rgb(0, 0, 0);
        }
      }

      .textfield-container {
        padding-bottom: 1rem;

        .textfield {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;

          &:last-child {
            margin-bottom: 0;
          }

          .form-group {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
          }

          .input-icon {
            position: absolute;
            top: 0;
            left: 18px;
            height: 48px;
            font-size: 20px;
            line-height: 48px;
            text-align: left;
            color: #a5a6a8;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
          }

          input {
            padding: 0px 9px 0px 55px;
            font-size: 15px;
            height: 48px;
            background-color: #edf0f5 !important;
            border: 2px solid #edf0f5;
            box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
            border-radius: 4px;
            width: 100%;
            line-height: 22px;
            letter-spacing: 0.5px;
            outline: none;
            color: gray;
          }
          input:focus,
          input:active {
            border: none;
            outline: none;
            box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
          }
          // input {
          // 	padding: 6px 9px;
          // 	font-size: 15px;
          // 	border: 2px solid rgba(0, 0, 0, 0.15);
          // 	border-radius: 4px;
          // 	width: 100%;
          // }

          .passwordField-container {
            position: relative;

            .eye-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0;
              top: 0;
              height: 100%;
              width: 40px;

              i {
                cursor: pointer;
                position: absolute;
                // right: 8px;
                // top: 6px;
              }
            }
          }
        }
      }

      .auth-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .auth-btns a {
        cursor: pointer !important;
        border-radius: 4px;
        height: 44px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        padding: 0 30px;
        letter-spacing: 1px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        text-align: center;
        border: none;
        background-color: #edf0f5;
        color: gray;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
      }
      .auth-btns a:active,
      .auth-btns a:focus {
        background-color: #edf0f5;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }
      .auth-btns a:hover {
        cursor: pointer !important;
        background-color: transparent;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }

      .modal-links {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Poppins;
        padding: 30px 0px 10px 0px;
      }
    }
  }

  .card-front {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #edf0f5;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .card-back {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-color: #edf0f5;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
  }

  .modal-box {
    width: 500px;
    margin: auto;
    z-index: 99999999;
    padding: 20px 0 0 0;
    padding-top: 30px;
    border-radius: 6px;
    // height: 100%;
    // border-style: solid;
    background-color: #edf0f5;
    // box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.5);

    .modal-header {
      display: flex;
      font-family: Poppins;
      color: gray;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 10px 43px 5px 43px;
    }

    .modal-body {
      padding: 1rem 3rem;

      .title {
        font-size: 13px;
        color: gray;
        font-weight: 600;
        margin-bottom: 4px;
        &:hover {
          color: rgb(0, 0, 0);
        }
      }

      .textfield-container {
        padding-bottom: 1rem;

        .textfield {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;

          &:last-child {
            margin-bottom: 0;
          }

          .form-group {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
          }

          .input-icon {
            position: absolute;
            top: 0;
            left: 18px;
            height: 48px;
            font-size: 20px;
            line-height: 48px;
            text-align: left;
            color: #a5a6a8;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
          }

          input {
            padding: 0px 9px 0px 55px;
            font-size: 15px;
            height: 48px;
            background-color: #edf0f5 !important;
            border: 2px solid #edf0f5;
            box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
            border-radius: 4px;
            width: 100%;
            line-height: 22px;
            letter-spacing: 0.5px;
            outline: none;
            color: gray;
          }
          input:focus,
          input:active {
            border: none;
            outline: none;
            box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
          }
          // input {
          // 	padding: 6px 9px;
          // 	font-size: 15px;
          // 	border: 2px solid rgba(0, 0, 0, 0.15);
          // 	border-radius: 4px;
          // 	width: 100%;
          // }

          .passwordField-container {
            position: relative;

            .eye-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0;
              top: 0;
              height: 100%;
              width: 40px;

              i {
                cursor: pointer;
                position: absolute;
                // right: 8px;
                // top: 6px;
              }
            }
          }
        }
      }

      .auth-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .auth-btns a {
        cursor: pointer !important;
        border-radius: 4px;
        height: 44px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        padding: 0 30px;
        letter-spacing: 1px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        text-align: center;
        border: none;
        background-color: #edf0f5;
        color: gray;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
      }
      .auth-btns a:active,
      .auth-btns a:focus {
        background-color: #edf0f5;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }
      .auth-btns a:hover {
        cursor: pointer !important;
        background-color: transparent;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }

      .modal-links {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Poppins;
        padding: 30px 0px 10px 0px;
      }
    }
  }
}

.modal-container-dark {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 20000000;

  .backdrop {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 50000000;
  }

  .card-3d-wrapper {
    z-index: 500000001;
    width: 450px;
    height: 540px;
    background-color: transparent;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    perspective: 800px;
  }

  .card-3d-inner {
    background-color: gray;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.5);
  }

  .otp-input {
    padding: 0px 9px 0px 9px;
    font-size: 15px;
    height: 48px;
    background-color: #3c3c3c !important;
    border: 2px solid #3c3c3c;
    box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
    border-radius: 4px;
    width: 75%;
    line-height: 22px;
    letter-spacing: 0.5px;
    outline: none;
    color: gray;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;

    &:focus,
    &:active {
      border: none;
      outline: none;
      box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
    }
  }

  .card-front,
  .card-back {
    // background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 300%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal-header {
      width: 70%;
      display: flex;
      font-family: Poppins;
      color: gray;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .modal-body {
      padding: 1rem 3rem;

      .title {
        font-size: 13px;
        color: gray;
        font-weight: 600;
        margin-bottom: 4px;
        &:hover {
          color: white;
        }
      }

      .textfield-container {
        padding-bottom: 1rem;

        .textfield {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;

          &:last-child {
            margin-bottom: 0;
          }

          .form-group {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
          }

          .input-icon {
            position: absolute;
            top: 0;
            left: 18px;
            height: 48px;
            font-size: 20px;
            line-height: 48px;
            text-align: left;
            color: #585858;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
          }

          input {
            padding: 0px 9px 0px 55px;
            font-size: 15px;
            height: 48px;
            background-color: #3c3c3c !important;
            border: 2px solid #3c3c3c;
            box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
            border-radius: 4px;
            width: 100%;
            line-height: 22px;
            letter-spacing: 0.5px;
            outline: none;
            color: gray;
          }
          input:focus,
          input:active {
            border: none;
            outline: none;
            box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
          }
          // input {
          // 	padding: 6px 9px;
          // 	font-size: 15px;
          // 	border: 2px solid rgba(0, 0, 0, 0.15);
          // 	border-radius: 4px;
          // 	width: 100%;
          // }

          .passwordField-container {
            position: relative;

            .eye-icon {
              color: rgb(94, 93, 93);
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0;
              top: 0;
              height: 100%;
              width: 40px;

              i {
                cursor: pointer;
                position: absolute;
              }
            }
          }
        }
      }

      .auth-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .auth-btns a {
        cursor: pointer !important;
        border-radius: 4px;
        height: 44px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        padding: 0 30px;
        letter-spacing: 1px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        text-align: center;
        border: none;
        background-color: #3c3c3c;
        color: gray;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
      }
      .auth-btns a:active,
      .auth-btns a:focus {
        background-color: #3c3c3c;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }
      .auth-btns a:hover {
        cursor: pointer !important;
        background-color: transparent;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }

      .modal-links {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Poppins;
        padding: 30px 0px 10px 0px;
      }
    }
  }

  .card-front {
    background-color: #3c3c3c;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .card-back {
    background-color: #3c3c3c;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
  }

  .modal-box {
    width: 500px;
    margin: auto;
    z-index: 99999999;
    padding: 20px;
    padding-top: 30px;
    border-radius: 6px;
    background: #3c3c3c;
    // box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.5);

    .modal-header {
      display: flex;
      font-family: Poppins;
      color: gray;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 10px 43px 5px 43px;
    }

    .modal-body {
      padding: 1rem 3rem;

      .title {
        font-size: 13px;
        color: gray;
        font-weight: 600;
        margin-bottom: 4px;
        &:hover {
          color: rgb(255, 255, 255);
        }
      }

      .textfield-container {
        padding-bottom: 1rem;

        .textfield {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;

          &:last-child {
            margin-bottom: 0;
          }

          .form-group {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
          }

          .input-icon {
            position: absolute;
            top: 0;
            left: 18px;
            height: 48px;
            font-size: 20px;
            line-height: 48px;
            text-align: left;
            color: gray;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
          }

          input {
            padding: 0px 9px 0px 55px;
            font-size: 15px;
            height: 48px;
            background-color: #3c3c3c !important;
            border: 2px solid #3c3c3c;
            box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
            border-radius: 4px;
            width: 100%;
            line-height: 22px;
            letter-spacing: 0.5px;
            outline: none;
            color: gray;
          }
          input:focus,
          input:active {
            border: none;
            outline: none;
            box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
          }
          // input {
          // 	padding: 6px 9px;
          // 	font-size: 15px;
          // 	border: 2px solid rgba(0, 0, 0, 0.15);
          // 	border-radius: 4px;
          // 	width: 100%;
          // }

          .passwordField-container {
            position: relative;

            .eye-icon {
              color: #a5a6a8;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0;
              top: 0;
              height: 100%;
              width: 40px;

              i {
                cursor: pointer;
                position: absolute;
                // right: 8px;
                // top: 6px;
              }
            }
          }
        }
      }

      .auth-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .auth-btns a {
        cursor: pointer !important;
        border-radius: 4px;
        height: 44px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        padding: 0 30px;
        letter-spacing: 1px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        text-align: center;
        border: none;
        background-color: #3c3c3c;
        color: gray;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
      }
      .auth-btns a:active,
      .auth-btns a:focus {
        background-color: #3c3c3c;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }
      .auth-btns a:hover {
        cursor: pointer !important;
        background-color: transparent;
        color: gray;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
      }

      .modal-links {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Poppins;
        padding: 30px 0px 10px 0px;
      }
    }
  }
}
