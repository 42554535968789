.darkDel {
	background-color: #3c3c3c;
}

.darkTitle {
	color: white;
}

.lightDel {
	background-color: aliceblue;
}

.lightTitle {
	color: gray;
}

.modal-content {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: montserrat;
	padding: 10px;
	font-weight: 300;
	font-size: 18px;
}

.del-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}
