.dark-body {
  .ui.modal.clear-favourite-modal {
    color: gray;
    width: 90%;
    border-radius: 2px;
    padding: 30px;
    background-color: #3c3c3c;
    max-width: 450px;

    > div {
      color: gray;
      background: #3c3c3c;
    }
  }

  .ui.modal.clear-favourite-modal-dark {
    color: gray;
    width: 90%;
    max-width: 450px;
    border-radius: 2px;
    background-color: #3c3c3c;
    padding: 30px;

    > div {
      color: gray;
      background: #3c3c3c;
    }
  }

  .custom-navlink {
    color: white;

    &:hover {
      color: #eee;
    }

    b {
      color: white;

      &:hover {
        color: #eee;
      }
    }
  }

  .tags-popup {
    @media (max-width: 992px) {
      min-width: fit-content !important;
    }

    &::before {
      background: #3c3c3c !important;
    }

    box-shadow: 0 1px 20px 0 #455a64cc;
    background-color: #3c3c3c !important;
    color: white !important;

    .content-details {
      color: white;
    }

    .no-tags-found {
      background-color: #3c3c3c !important;
    }
  }

  .simillar-popup {
    @media (max-width: 992px) {
      min-width: fit-content !important;
      background-color: red;
    }

    &::before {
      background: #3c3c3c !important;
    }

    box-shadow: 0 1px 20px 0 #455a64cc;
    background-color: #3c3c3c !important;
    color: white !important;

    .content-details {
      color: white;
    }

    .no-tags-found {
      background-color: #3c3c3c !important;
    }
  }

  .tags-input {
    background-color: #cccccc;
    .tag {
      background-color: #808080;
    }
    input {
      background-color: #cccccc;

      &::placeholder {
        color: gray;
        padding-left: 8px;
      }
    }
  }

  .card {
    box-shadow: 0 1px 20px 0 #455a64cc;
    background-color: #3c3c3c !important;
    color: white;

    .content-details {
      color: white;
    }
  }

  .dropdown {
    .menu {
      color: white !important;
      background-color: #3c3c3c !important;
    }
  }

  .sites-dropdown-wrapper {
    background-color: #3c3c3c;

    .menu {
      background-color: #3c3c3c !important;
      color: white !important;

      b {
        color: white !important;
      }
    }
  }

  .gear-dropdown {
    .item {
      color: white !important;
    }

    > div {
      .glyph-icon {
        &::before {
          font-size: 1em;
          color: white;
        }
      }
    }
  }

  .clear-favourite-modal {
    input {
      background-color: #ccc !important;

      &::placeholder {
        color: gray !important;
      }
    }
  }
  .clear-favourite-modal-dark {
    input {
      background-color: #3c3c3c !important;

      &::placeholder {
        color: gray !important;
      }
    }
  }
}

.dark-mode {
  background-color: #3c3c3c;
  color: white !important;

  .post-title {
    cursor: pointer;
    color: white !important;
  }

  .topbar-nepathya {
    background-color: #2e3035 !important;
  }

  .reicon {
    color: white;
  }

  .reicon.active {
    color: #0388e5;
  }

  .layout-topbar {
    background-color: #3c3c3c !important;
  }

  .card {
    box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.15);
    background: #3c3c3c;
    color: white;

    .content-details {
      color: white;
    }
  }

  .underlined-border {
    border-bottom: 1px solid rgba(160, 160, 160, 0.25);
    color: white;
    padding-left: 35px !important;
  }

  .layout-logo {
    color: grey !important;
  }

  .filter-bar {
    background-color: #3c3c3c;
    color: #8a8a8a;
  }

  input,
  textarea,
  .p-inputtext {
    background: #cccccc;
  }

  .p-dropdown-trigger,
  .p-dropdown {
    background-color: #cccccc !important;
    background: #cccccc !important;
  }

  .icon-center-content {
    input {
      background: transparent;
    }
  }

  .layout-menu-fixed {
    background-color: #3c3c3c;
    padding: 0;
  }

  .layout-menu-fixed-top {
    background-color: #3c3c3c;
    padding: 0;
  }

  .ui.dropdown .menu > .divider {
    border-top: 1px solid silver !important;
  }

  .ui.form .field > label {
    color: white;
  }

  .tags-bar {
    margin-top: -1px;
    box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #3c3c3c;
    .ui.label {
      box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
      background-color: hsla(0, 0%, 50.2%, 0.1);
      font-size: 0.925em;
      cursor: pointer;
      border-width: 1px;
      color: gray;

      &:hover {
        background-color: hsla(0, 0%, 50.2%, 0);
        color: white;
      }
    }
  }

  .selection {
    .item {
      color: white !important;
    }
  }

  input,
  .selection.dropdown,
  .tags-input,
  textarea {
    background-color: #ccc !important;

    .tag {
      background-color: gray;
    }

    &::placeholder,
    .default.text {
      color: gray !important;
    }
  }

  // .p-dropdown-item {

  //   background-color: #ccc !important;
  //   border-bottom: 1px solid silver !important;
  // }

  .searchbox {
    background-color: transparent !important;
  }

  .ui.basic.red.label {
    color: red !important;
    border: silver !important;
    background: silver !important;
  }

  .highlighted {
    // background-color: #575656;
    background-color: transparent;
  }

  //favourite dropdown
  .cat-parent-menu {
    border-style: none !important;

    .favourites-sortby-dropdown {
      background: #303030 !important;
      border-style: none !important;
      color: rgba(255, 255, 255, 0.6) !important;
      .menu.transition.visible {
        background-color: #303030 !important;
      }
    }
  }
}

.ui.pointing.dropdown > .menu:after {
  background: red;
}

.transition .visible .itemWhite {
  color: red !important;
}
