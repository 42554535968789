.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  #switch-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 55px;
    height: 28px;
    background: #9ba4b0;
    display: block;
    border-radius: 15px;
    position: relative;
    background-image: url("../../assets//img/cross.svg"); // replace with your own symbol image
    background-size: 10px 10px; // adjust symbol size
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
  }

  #switch-label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 15px;
    transition: 0.3s;
  }

  input:checked + #switch-label {
    background: #9ba4b0;
    background-image: url("../../assets//img/check.svg"); // replace with your own symbol image
    background-size: 11px 11px; // adjust symbol size
    background-repeat: no-repeat;
    background-position: 10px 50%;
  }

  input:checked + #switch-label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  #switch-label:active:after {
    width: 20px;
  }
}
