.menu {
  display: flex;
  justify-content: flex-start;

  position: relative;
  z-index: 1;
  padding: 20px;
  width: 100%;
  margin: 20px auto;
  border-bottom: 0px solid #000;

  @media (max-width: 650px) {
    align-items: center;
    justify-content: center;
  }

  .light-info {
    color: gray !important;
    background-color: #edf0f4;
  }

  .dark-info {
    background-color: #3c3c3c;
    color: gray !important;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    cursor: pointer;
    transition: color 0.3s ease-out;
    text-decoration: none;
    margin: 0px 7px;
    font-size: 0.925em !important;
    font-weight: bold;
    border-radius: 0.28571429rem;

    &.active {
      color: gray !important;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
    }
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    border-radius: 0.28571429rem;
    background-color: transparent !important;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
  }
}

.horizontal-menu-scroll {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-width: none !important;

  @media (max-width: 650px) {
    overflow-x: auto;
    padding-left: 330px;
    padding-right: 40px;
  }
}

.info-indicator-left {
  // border: 1px solid red;
  position: absolute;
  width: 35px;
  height: 40px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #3c3c3c;
  // border: 1px solid red;

  i {
    font-size: 13px !important;
    color: gray;
    &:hover {
      color: white;
    }
  }
}

.info-indicator-left-light {
  // border: 1px solid red;
  position: absolute;
  width: 35px;
  height: 40px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #edf0f4;
  // border: 1px solid red;

  i {
    font-size: 13px !important;
    color: gray;
    &:hover {
      color: black;
    }
  }
}

.info-indicator-right {
  // border: 1px solid blue;
  position: absolute;
  width: 35px;
  height: 40px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #3c3c3c;

  i {
    padding-right: 20px !important;
    padding-bottom: 15px !important;
    font-size: 13px !important;
    color: gray;
    &:hover {
      color: white;
    }
  }
}

.info-indicator-right-light {
  // border: 1px solid blue;
  position: absolute;
  width: 35px;
  height: 40px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #edf0f4;
  // border: 1px solid red;

  i {
    padding-right: 20px !important;
    padding-bottom: 15px !important;
    font-size: 13px !important;
    color: gray;
    &:hover {
      color: black;
    }
  }
}

.scroll-hide {
  display: none;
}
