.grid {
	margin: 0;
	padding: 0;
	list-style: none;
	user-select: none;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	grid-gap: 1px;
	// min-width: 160px;
	max-width: 300px;
}

.box {
	background-color: transparent;
	resize: horizontal;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	margin: 3px;
	position: relative;
}

[draggable="true"] {
	cursor: move;
}

.to {
	transform: scale(1.025);
	transition: all 0.5s;
	border: 1px dashed rgba(0, 0, 0, 0.3);
	background-color: transparent;
	padding: 0px;
}

#autoComplete {
	position: relative;
	display: none;
	width: 53%;
	min-height: 30px;
	border: 1px #dadada solid;
	z-index: 999999999999;
	margin-top: -30px;
	padding-bottom: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.autoCompleteTag:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
