.lds-ringg {
	display: inline-block;
	position: relative;
	width: 18px;
	height: 18px;
    padding-right: 0px;
    padding-top: 0px;
}
.lds-ringg div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 13px;
	height: 13px;
	margin: 3px;
	border: 1px solid #fff;
	border-radius: 50%;
	animation: lds-ringe 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ringg div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ringg div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ringg div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ringe {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
