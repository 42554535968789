.subscribe-textfield {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;

	&:last-child {
		margin-bottom: 0;
	}

	.form-group {
		position: relative;
		display: block;
		margin: 0;
		padding: 0;
	}

	.input-icon {
		position: absolute;
		top: 0;
		left: 18px;
		height: 48px;
		font-size: 20px;
		line-height: 48px;
		text-align: left;
		color: #a5a6a8;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}

	input {
		padding: 0px 9px 0px 55px;
		font-size: 15px;
		height: 48px;
		background-color: #edf0f5 !important;
		border: 2px solid #edf0f5;
		box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
		border-radius: 4px;
		width: 100%;
		line-height: 22px;
		letter-spacing: 0.5px;
		outline: none;
		color: gray;
	}
	input:focus,
	input:active {
		border: none;
		outline: none;
		box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
	}

	.passwordField-container {
		position: relative;

		.eye-icon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 0;
			top: 0;
			height: 100%;
			width: 40px;

			i {
				cursor: pointer;
				position: absolute;
			}
		}
	}
}

.subscribe-textfield-dark {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;

	&:last-child {
		margin-bottom: 0;
	}

	.form-group {
		position: relative;
		display: block;
		margin: 0;
		padding: 0;
	}

	.input-icon {
		position: absolute;
		top: 0;
		left: 18px;
		height: 48px;
		font-size: 20px;
		line-height: 48px;
		text-align: left;
		color: #a5a6a8;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}

	input {
		padding: 0px 9px 0px 55px;
		font-size: 15px;
		height: 48px;
		background-color: #3c3c3c !important;
		border: 2px solid #3c3c3c;
		box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.4);
		border-radius: 4px;
		width: 100%;
		line-height: 22px;
		letter-spacing: 0.5px;
		outline: none;
		color: gray;
	}
	input:focus,
	input:active {
		border: none;
		outline: none;
		box-shadow: 0 4px 14px 0 rgba(21, 21, 21, 0.2) inset;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px #3c3c3c inset !important;
	}
	input:-webkit-autofill {
		-webkit-text-fill-color: gray !important;
	}

	.passwordField-container {
		position: relative;

		.eye-icon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 0;
			top: 0;
			height: 100%;
			width: 40px;

			i {
				cursor: pointer;
				position: absolute;
			}
		}
	}
}

.sub-btn-dark {
	.auth-btns {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.auth-btns a {
		cursor: pointer !important;
		border-radius: 4px;
		height: 44px;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
		padding: 0 30px;
		letter-spacing: 1px;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-align-items: center;
		-moz-align-items: center;
		-ms-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		-ms-flex-pack: center;
		text-align: center;
		border: none;
		background-color: #3c3c3c;
		color: gray;
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
	}
	.auth-btns a:active,
	.auth-btns a:focus {
		background-color: #3c3c3c;
		color: gray;
		box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
	}
	.auth-btns a:hover {
		cursor: pointer !important;
		background-color: transparent;
		color: gray;
		box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
	}
}

.sub-btn {
	.auth-btns {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.auth-btns a {
		cursor: pointer !important;
		border-radius: 4px;
		height: 44px;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
		padding: 0 30px;
		letter-spacing: 1px;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-align-items: center;
		-moz-align-items: center;
		-ms-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		-ms-flex-pack: center;
		text-align: center;
		border: none;
		background-color: #edf0f5;
		color: gray;
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
	}
	.auth-btns a:active,
	.auth-btns a:focus {
		background-color: #edf0f5;
		color: gray;
		box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
	}
	.auth-btns a:hover {
		cursor: pointer !important;
		background-color: transparent;
		color: gray;
		box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.4) inset;
	}
}

.subs-close {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	right: 20px;
	position: absolute;

	svg {
		font-size: 18px;
		color: gray;
		cursor: pointer;
	}
}
