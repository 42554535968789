.submission-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  width: 100%;

  &__header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: bold;
    }

    .submission-title {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__options {
      display: flex;
      max-height: 30px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: rgba(138, 138, 138, 0.5);

        .dropdown {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0px;
          border-radius: 3px;
          border-style: solid;
          border-width: 1px;
          border-color: rgba(0, 0, 0, 0.3);

          .sub-menu {
            .sub-item {
              height: 40px !important;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              border-bottom-style: solid !important;
              border-bottom-width: 1px !important;
              border-color: rgba(138, 138, 138, 0.5) !important;
            }
          }
        }
      }
    }

    &__search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      border-style: solid;
      border-color: rgba(138, 138, 138, 0.5);
      border-width: 1px;
      padding: 0px 5px 0px 5px;

      svg {
        font-size: 15px;
        color: rgba(138, 138, 138);
      }

      .input {
        border-style: none;
        background-color: rgab(0, 0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent !important;
        color: rgba(138, 138, 138);

        &:focus {
          outline: none !important;
          border: none;
          background-color: transparent !important;
        }
      }
    }
  }

  &__subs-table {
    padding: 30px 0px 30px 0px;
    display: flex;
    align-items: center;
    width: 100%;

    table {
      border-collapse: separate !important;
      width: 100%;

      .link {
        text-align: center;
        font-weight: bold;
      }

      .date {
        text-align: left;
      }

      .status {
        text-align: center;
      }

      .points {
        text-align: right;
        font-weight: bold;
      }

      th {
        padding: 10px 10px 10px 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: rgba(138, 138, 138, 0.5);
        border-collapse: collapse;
      }

      td {
        padding: 10px 10px 10px 10px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: rgba(138, 138, 138, 0.5);
        border-collapse: collapse;

        svg {
          font-size: 15px;
          margin-bottom: -2px;
          margin-left: 5px;
          opacity: 0;
        }
      }

      .headerr {
        &:hover {
          background-color: rgba(255, 255, 255, 0);
          border-radius: 0px;
          box-shadow: none;
        }
      }
      tr {
        cursor: pointer;
        &:hover {
          border-radius: 0px;
          box-shadow: 0 7px 8px 5px rgb(100 100 111 / 20%);

          td {
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .pagination-btn-dark {
      font-size: 14px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      background-color: rgba(138, 138, 138, 0.1);
      margin: 3px;

      &:hover {
        cursor: pointer;
        background-color: rgba(138, 138, 138, 0.2);
      }

      svg {
        font-size: 13px;
      }
    }

    .pagination-btn-light {
      font-size: 14px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      background-color: #dedede;
      margin: 3px;

      &:hover {
        cursor: pointer;
        background-color: #d4d5d5;
      }

      svg {
        font-size: 13px;
      }
    }
  }
}

.submission-table-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: bold;
      font-size: 19px;
      padding: 0px 0px 20px 0px;
    }

    .submission-title {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__options {
      display: flex;
      max-height: 30px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: rgba(138, 138, 138, 0.5);

        .dropdown {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0px;
          border-radius: 3px;
          border-style: solid;
          border-width: 1px;
          border-color: rgba(0, 0, 0, 0.3);

          .sub-menu {
            .sub-item {
              height: 40px !important;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              border-bottom-style: solid !important;
              border-bottom-width: 1px !important;
              border-color: rgba(138, 138, 138, 0.5) !important;
            }
          }
        }
      }
    }

    &__search {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      border-style: solid;
      border-color: rgba(138, 138, 138, 0.5);
      border-width: 1px;
      padding: 0px 5px 0px 5px;
      width: 130px;

      svg {
        font-size: 15px;
        color: rgba(138, 138, 138);
      }

      .input {
        border-style: none;
        background-color: rgab(0, 0, 0, 0, 0);
        border-radius: 0px;
        background-color: transparent !important;
        color: rgba(138, 138, 138);
        width: 100px;

        &:focus {
          outline: none !important;
          border: none;
          background-color: transparent !important;
        }
      }
    }
  }

  &__subs-table {
    padding: 30px 0px 30px 0px;
    display: flex;
    align-items: center;
    width: 100%;

    table {
      border-collapse: separate !important;
      width: 100%;
      font-size: 11px;

      .link {
        text-align: center;
        font-weight: bold;
      }

      .date {
        min-width: 100px;
        text-align: left;
      }

      .status {
        text-align: center;
      }

      .points {
        text-align: right;
        font-weight: bold;
      }

      th {
        padding: 10px 10px 10px 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: rgba(138, 138, 138, 0.5);
        border-collapse: collapse;
      }

      td {
        padding: 10px 10px 10px 10px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: rgba(138, 138, 138, 0.5);
        border-collapse: collapse;

        svg {
          font-size: 15px;
          margin-bottom: -2px;
          margin-left: 5px;
          opacity: 0;
        }
      }

      .headerr {
        &:hover {
          background-color: rgba(255, 255, 255, 0);
          border-radius: 0px;
          box-shadow: none;
        }
      }
      tr {
        cursor: pointer;
        &:hover {
          border-radius: 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px -1px 8px 5px;

          td {
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .pagination-btn-dark {
      font-size: 14px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      background-color: rgba(138, 138, 138, 0.1);
      margin: 3px;

      &:hover {
        cursor: pointer;
        background-color: rgba(138, 138, 138, 0.2);
      }

      svg {
        font-size: 15px;
      }
    }

    .pagination-btn-light {
      font-size: 12px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      background-color: #dedede;
      margin: 3px;

      &:hover {
        cursor: pointer;
        background-color: #d4d5d5;
      }

      svg {
        font-size: 15px;
      }
    }
  }
}
