.popup-wrapper .item-popup {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 25px;
  background-color: grey;
  border: 2px solid gray;
  width: 32px;
  max-height: 32px;
  // margin-top: 5px;
  transition: width 0.15s 0.25s ease-in-out, max-height 0.25s ease-in-out;
}

a.popup-button {
  margin-top: -6px !important;
}

.popup-wrapper .item-popup .popup-button {
  box-sizing: border-box;
  float: right;
  width: 35px;
  height: 35px;
  margin: 0 0 0 0;
  color: white !important;
  background-color: gray;
  content: "";

  text-decoration: none;
  text-align: center;
  line-height: 31px;
  border: 2px solid gray;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  margin-right: -3px;
}

.deselected img {
  opacity: 0.4 !important;
}

.selected img {
  opacity: 1 !important;
}

.popup-wrapper {
  position: fixed;
  border: 0px solid red;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 20px;
  z-index: 200;
  transform: scale(1.25);
}

.floaty {
  // background-color: red;
  border: 0px solid green;
  padding-bottom: 25px !important;
  transform: scale(0.7);
  // width: 300px;
}

.floaty-btn-label,
.floaty-btn-label2 {
  margin-top: -17px;
}

.floaty-list {
  float: right !important;
  border-color: #3c80f6;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.popup-wrapper .item-popup .popup-content {
  margin: 0;
  padding-left: 5px;
  padding-top: 4px;
  color: transparent;
  font-size: 14px;
  line-height: 0;

  transition: font-size 0.5s 0.3s, color 0.3s;
  text-align: center;
}

// .popup-wrapper .item-popup:hover {
//   width: 135px;
//   min-height: 32px;
//   max-height: 35px;
//   border-style: solid;
//   transition: 0.25s ease-in-out;
// }

.popup-wrapper .item-popup-active {
  width: 135px;
  min-height: 32px;
  max-height: 35px;
  border-style: solid;
  transition: 0.25s ease-in-out;
}

.popup-wrapper .item-popup .popup-button {
  transform: rotate(-90deg);
}

.popup-wrapper .item-popup-content .popup-content {
  font-size: 12px;
  transition: font-size 0.25s 0.1s, color 0.25s 0.2s;
  color: white;
  text-align: center;
  font-weight: bold;
}

.popup-wrapper .item-popup .container {
  visibility: hidden;
}

// trigger random list display on hover
// .popup-wrapper .item-popup:hover .container,
.popup-wrapper:active .item-popup .container {
  visibility: visible;
}

#pink {
  color: #f22496;
}

#green {
  color: #3fad3b;
}

#red {
  color: #b53131;
}

#blue {
  color: #007ad9;
}
#orange {
  color: #ffa500;
}

.grey {
  color: grey !important;
}

span.exclude {
  white-space: nowrap !important;
  color: grey !important;
}

.gear-icon {
  width: 17px;
  height: 17px;
  margin-top: 1px;
  margin-left: 1px;
}

@import url(https://fonts.googleapis.com/css?family=Roboto);

/* Setup styles - you don't need these */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f0f0f0;
}

/* Component styles - this is what you need to grab */
.absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.floaty {
  position: absolute;
  // bottom: -70px !important;
  right: -5px;
  // margin-bottom: -21px;
  // background-color: red;
  width: 90px;
  // bottom: -5.5rem;
  // height: 100vh !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#floaty-list {
  margin: 0 0 20px;
  padding: 0 0 0 60px;
  list-style: none;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  // background-color: blue;
  // position: fixed;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  // justify-content: flex-end;
  // bottom: 0px;

  .random-link-list {
    background-color: green;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    bottom: 0px;
    max-height: 100%;
    overflow-y: scroll !important;
  }
}
.floaty:hover .floaty-list {
  opacity: 1;
}
.floaty-list-item {
  position: relative;
  width: 45px;
  height: 45px;

  cursor: pointer;
  background-color: #f0f0f0;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.floaty-list-item > img {
  border-radius: 50%;
}
.floaty-list-item--yellow {
  background-color: #d4a717;
}
.floaty-list-item--yellow > svg {
  fill: #fff;
}
.floaty-list-item--blue {
  background-color: #3c80f6;
}
.floaty-list-item--blue > svg {
  fill: #fff;
}
.floaty-list-item-label {
  position: absolute;
  top: 0px;
  right: 120%;
  padding: 12px 24px;
  font-size: 15px;
  color: #fff;
  background-color: #f0f0f0;
  border-radius: 2px;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}
.floaty-list-item:hover > .floaty-list-item-label {
  opacity: 1;
}
.floaty-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  float: right;
  margin-bottom: 10px;
  bottom: 0;
  right: 0;
  margin-right: 0px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 0px solid rgba(0, 0, 0, 0.15);
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
}

.floaty-btn:hover img {
  content: url(https://app.pikilinks.com/wp-content/uploads/2021/05/gear-grey2.png);
}
.floaty-btn:hover .floaty-btn-label {
  opacity: 1;
}
.toggle-btn:hover .floaty-btn-label2 {
  opacity: 1;
}
.floaty-btn-label {
  position: absolute;
  top: 11px;
  right: 130%;
  padding: 6px 12px;
  font-size: 16px;
  color: grey;
  font-weight: bold;
  background-color: #f0f0f0;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.floaty-btn-label2 {
  position: absolute;
  top: 11px;
  right: 130%;
  padding: 6px 12px;
  font-size: 16px;
  color: grey;
  font-weight: bold;
  background-color: #f0f0f0;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.floaty-btn-icon {
  transition: all 0.2s;
}
.floaty-btn-icon-plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.floaty.is-active .floaty-btn-icon-plus {
  opacity: 0;
  -webkit-transform: rotate(13deg);
  transform: rotate(130deg);
}
.floaty-btn-icon-create {
  opacity: 0;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.floaty.is-active .floaty-btn-icon-create {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cat-icon {
  border: 0px solid red;
  max-width: 25px !important;
  max-height: 25px !important;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 0px !important;
}

.toggle-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.1);
  bottom: 10px;
  right: 25px;
  border: 0px solid rgba(0, 0, 0, 0.15);
}

label.tog > input {
  display: none; /* Hide the checkbox */
}

label.tog > input + span {
  text-indent: -9000px; /* Make text Accessible but not visible */
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: center / contain no-repeat
    url("https://app.pikilinks.com/wp-content/uploads/2021/05/Window.png"); /*Play*/
}

label.tog > input:checked + span {
  cursor: pointer;
  background-image: url("https://app.pikilinks.com/wp-content/uploads/2021/05/page.png"); /*Pause*/
}

.toggle-menus {
  width: 60px;
  height: 30px;
  margin-left: -75px;
  margin-top: -3px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &__item {
    flex: 0.5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;

    svg {
      opacity: 0.5;
    }

    img {
      opacity: 0.5;
    }
  }

  .toggle-menu-active {
    svg {
      opacity: 1;
    }

    img {
      opacity: 1;
    }
  }
}
