.multiselect {
	width: 100%;
	// padding: 5px;
	min-height: 40px;
	z-index: 999999999;
}

.selectBox {
	position: relative;
	border-style: none;
	display: flex;
	width: 100%;
	height: 100%;
	z-index: inherit;
}

.selectBox select {
	width: 100%;
}

.overSelect {
	position: absolute;
	display: flex;
	align-items: center;
	padding-left: 0px !important;
	min-height: 40px;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

#checkboxes {
	display: none;
	width: 62%;
	margin-left: -35px;
	border: 1px #dadada solid;
	z-index: 999999999999;
	margin-top: 5px;
}

#checkboxes label {
	display: block;
	padding: 10px;
	border-bottom-style: solid;
	border-width: 1px;

	input {
		margin-right: 5px;
	}
}

#checkboxes #sublabel {
	padding: 7px 7px 7px 35px;
	input {
		margin-right: 5px;
	}

	img[src=""],
	img:not([src]) {
		display: none;
	}
}

#checkboxes #mainlabel {
	img[src=""],
	img:not([src]) {
		display: none;
	}
}

#checkboxes label:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.multi-scroll {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	scrollbar-width: thin;
	padding-left: 7px;
}

.multi-scroll::-webkit-scrollbar {
	height: 2px;
}
