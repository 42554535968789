/* General */
$fontSize: 14px;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;
$headerColor: #edf0f5;

/* Menu Common */
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: #1fa1fc;

/* Menu Light */
$menuBgColorFirst: #edf0f5;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #2b2f3d;
$menuitemHoverColor: #0388e5;
$menuitemActiveColor: #0388e5;
$menuitemActiveBgColor: #f2f5f8;
$menuitemBorderColor: rgba(225, 227, 235, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst: #3c3c3c;
$menuDarkBgColorLast: #302f2f;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: #0388e5;
$menuitemDarkActiveColor: #575656;
$menuitemDarkActiveBgColor: #272727;
$darkestBackgroundColor: #272727;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor: #0388e5;
$topbarRightBgColor: #1bf407;
$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: #c3e8fb;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #edf0f5;

/*visited color */
$darkModeVisited: #ffc0cb;
$lightModeVisited: #707070;
