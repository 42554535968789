.error {
    color: red;
    font-size: 13px;
    font-weight: 600;
    margin-left: 2rem;
    text-align: right;
}

.errorField {
    border-color: red !important;

    &:focus-visible {
        border-color: red !important;
        outline: none;
    }
}
